<template>
  <div>
    <div
      v-for="(item, index) in uploadList"
      :key="index"
      class="demo-upload-list"
    >
      <template v-if="item.status === 'finished'">
        <img
          v-if="item.url"
          width="100px"
          :src="openImg(item.url)"
        >
        <img
          v-else
          width="100px"
          :src="openImg(item.response.data.url)"
        >
        <div class="demo-upload-list-cover">
          <Icon
            type="ios-eye-outline"
            @click.native="handleView(item)"
          />
          <Icon
            type="ios-trash-outline"
            @click.native="handleRemove(item)"
          />
        </div>
      </template>
      <template v-else>
        <Progress
          v-if="item.showProgress"
          :percent="item.percentage"
          hide-info
        />
      </template>
    </div>
    <Upload
      ref="upload"
      :show-upload-list="false"
      :default-file-list="defaultList"
      :on-success="handleSuccess"
      :format="formats"
      :max-size="1024*10"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :before-upload="handleBeforeUpload"
      :headers="{ 'Authorization':token, 'x-app':xApp }"
      multiple
      type="drag"
      action="https://zdwz.api.sdcom.gov.cn/api/files"
      :style="!uploadList.length || imgNumber!==1 ?'display: inline-block;width:58px;': 'display:none' "
    >
      <div
        v-if="!uploadList.length || imgNumber!==1"
        style="width: 58px;height:58px;line-height: 58px;"
      >
        <Icon
          :type="imgIcon"
          size="20"
        />
      </div>
    </Upload>
    <Modal
      v-model="visible"
      :title="imgName"
    >
      <img
        v-if="visible"
        :src="imgSrc"
        style="width: 100%"
      >
    </Modal>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Array,
        default: null,
      },
      imgIcon: {
        type: String,
        default: 'ios-image',
      },
      imgNumber: {
        type: Number,
        default: 5,
      },
      upType: {
        type: Array,
        default: null,
      },
    },
    data () {
      return {
        defaultList: [],
        uploadList: [],
        files: [],
        fileUrlIds: {},
        fileIds: [],
        field: '',
        token: '',
        xApp: '',
        visible: false,
        imgName: '',
        imgSrc: '',
        imgList: ['.jpg', '.jpeg', '.png'],
        pdf: require('../../../../src/assets/pdf.png'),
        xls: require('../../../../src/assets/excel.png'),
        doc: require('../../../../src/assets/word.png'),
        zip: require('../../../../src/assets/zip.png'),
        ppt: require('../../../../src/assets/ppt.png'),
        formats: this.upType || ['jpg', 'jpeg', 'png', 'pdf', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'],
      }
    },
    watch: {
      async value (ids) {
        console.log('ids', ids);
        if (this.defaultList.length === 0) {
          this.defaultList = await this.getFileUrl(ids)
        }
        this.$nextTick(() => {
          // 这样打印出来就会有值了
          this.uploadList = this.$refs.upload.fileList
        })
      },
      fileUrlIds () {
        this.onClick()
      },
      imgIcon (val) {
        this.imgIcon = val
      },
    },
    created () {
      this.token = localStorage.token
      this.xApp = localStorage.xApp
    },
    methods: {
      async getFileUrl (filesId) {
        if (filesId.length > 0) {
          const res = await this.$axios.get('/files/query', {
            params: {
              ids: filesId.toString(),
            },
          })
          const resData = res.data.data
          const urlArr = []
          resData.map(val => {
            this.$set(this.fileUrlIds, val.id, val.url)
            urlArr.push({
              name: val.oldName,
              url: val.url,
            })
          })
          return urlArr
        } else {
          return []
        }
      },
      onClick () {
        this.files = Object.keys(this.fileUrlIds)
        this.uploadList = this.$refs.upload.fileList
        this.$emit('input', this.files)
      },
      // 上传成功
      handleSuccess (res, file, fileList) {
        this.$set(this.fileUrlIds, file.response.data.id, file.response.data.url)
        this.$Notice.success({
          title: '上传成功',
        })
      },
      openImg (url) {
        var isImg = false
        this.imgList.map(val => {
          if (url.indexOf(val) !== -1) {
            isImg = true
          }
        })
        if (isImg) {
          return url
        } else if (url.indexOf('.pdf') !== -1) {
          return this.pdf
        } else if (url.indexOf('.xls') !== -1) {
          return this.xls
        } else if (url.indexOf('.zip') !== -1) {
          return this.zip
        } else if (url.indexOf('.ppt') !== -1) {
          return this.ppt
        } else {
          return this.doc
        }
      },
      handleView (item) {
        console.log('item', item);
        var isImg = false
        this.imgList.map(val => {
          if (item.name.indexOf(val) !== -1) {
            console.log('val', val, item);
            isImg = true
          }
        })
        if (isImg) {
          this.imgName = item.name
          item.url ? this.imgSrc = item.url : this.imgSrc = item.response.data.url
          this.visible = true
        } else {
          const link = document.createElement('a')
          link.style.display = 'none'
          item.url ? link.href = item.url : link.href = item.response.data.url
          link.target = '_blank'
          link.click()
          link.remove()
        }
      },
      handleRemove (file) {
        // 删除已传列表缩略图
        const fileList = this.$refs.upload.fileList
        this.$refs.upload.fileList.splice(fileList.indexOf(file), 1)
        let filesUrl
        if (file.url) {
          filesUrl = file.url
        } else {
          filesUrl = file.response.data.url
        }
        for (const id in this.fileUrlIds) {
          if (this.fileUrlIds[id] === filesUrl) {
            this.$delete(this.fileUrlIds, id)
          }
        }
      },
      handleFormatError (file) {
        this.$Notice.warning({
          title: '上传的文件格式不正确',
          desc: `文件格式应为：${this.formats.toString()}中的一种`,
        })
      },
      handleMaxSize (file) {
        this.$Notice.warning({
          title: '文件超出了限制体积大小',
          desc: '文件  ' + file.name + ' 最大不能超过 2M.',
        })
      },
      handleBeforeUpload () {
        const check = this.uploadList.length < this.imgNumber
        if (!check) {
          this.$Notice.warning({
            title: `最多只能上传${this.imgNumber}个文件`,
          })
        }
        return check
      },
    },
  }
</script>

<style lang="scss" scoped>
    .demo-upload-list{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>
